import { Box, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import Alert from '../../../../../components/Alert/Alert';
import Button from '../../../../../components/Button/Button';
import ButtonGroup from '../../../../../components/ButtonGroup/ButtonGroup';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import DateField from '../../../../../components/DateField/DateField';
import { SimStatus } from '../../../../../models/UserDevicesModels';
import { RootState } from '../../../../../stores/Store';
import { updateRequest } from '../../../../../stores/TerminateConnectionStore';

interface TerminateConnectionDateSelectionStepProps {
  nextStep: () => void;
  onClose: () => void;
}

function TerminateConnectionDateSelectionStep({ nextStep, onClose }: TerminateConnectionDateSelectionStepProps) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { request } = useSelector((state: RootState) => state.terminateConnectionReducer);
  const { simStatus } = useSelector((state: RootState) => state.upgradeDeviceReducer);

  /**
   * Verifies if the date is in a valid format
   * @param val
   */
  const validateDateFormat = (val: string | undefined) => {
    if (val) {
      const dateObj = DateTime.fromISO(val);
      if (!dateObj.isValid) {
        return false;
      }
    }
    return true;
  };

  /**
   * Verifies if the date is in a valid format
   * @param val
   */
  const validateFutureDate = (val: string | undefined) => {
    if (val) {
      const today = DateTime.now();
      const dateObj = DateTime.fromISO(val);
      return (
        dateObj.startOf('day') >= today.plus({ day: 30 }).startOf('day') &&
        dateObj.startOf('day') < today.plus({ year: 1 }).startOf('day')
      );
    }
    return true;
  };

  const formik = useFormik({
    initialValues: { terminationDate: request.terminationDate, suspendImmediately: request.suspendImmediately },
    enableReinitialize: false,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: yup.object({
      terminationDate: yup
        .string()
        .test('date', t('common.inputValidations.date'), validateDateFormat)
        .test('futureDate', t('pages.userDetails.terminateConnectionDrawer.futureDate'), validateFutureDate)
        .nullable(),
    }),
    onSubmit: (values) => {
      dispatch(
        updateRequest({
          terminationDate: values.terminationDate as string,
          suspendImmediately: values.suspendImmediately,
        }),
      );
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ marginBottom: '2rem' }}>
        <Typography sx={{ marginBottom: '2.5rem' }}>
          {t('pages.userDetails.terminateConnectionDrawer.chooseTerminationDate')}
        </Typography>
        <Box sx={{ input: { width: '180px' } }}>
          <DateField
            onChange={(date) => {
              formik.setFieldValue('terminationDate', date?.toISO() as string);
            }}
            error={Boolean(formik.errors.terminationDate)}
            label={t('pages.userDetails.terminateConnectionDrawer.scheduleTermination')}
            feedback={formik.errors.terminationDate ? formik.errors.terminationDate : ''}
            value={formik.values.terminationDate ? DateTime.fromISO(formik.values.terminationDate) : null}
          />
        </Box>
      </Box>

      <Alert
        alertType="info"
        message={`${t('pages.userDetails.terminateConnectionDrawer.pleaseNote')}${
          simStatus !== SimStatus.SUSPENDED ? ` ${t('pages.userDetails.terminateConnectionDrawer.immediateNote')}` : ''
        }`}
        fontSize="0.875rem"
      />
      {simStatus !== SimStatus.SUSPENDED ? (
        <Box sx={{ marginTop: '2.5rem' }}>
          <Checkbox
            checked={formik.values.suspendImmediately}
            onChange={(e) => {
              formik.setFieldValue('suspendImmediately', e.target.checked);
            }}
            label={t('pages.userDetails.terminateConnectionDrawer.suspendImmediately')}
          />
        </Box>
      ) : null}

      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} />
          <Button
            label={t('common.continue')}
            color="primaryButton"
            disabled={!formik.isValid}
            onClick={formik.submitForm}
          />
        </ButtonGroup>
      </Box>
    </form>
  );
}

export default TerminateConnectionDateSelectionStep;
