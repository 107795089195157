import { Box, Divider, styled, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../../components/Button/Button';
import ButtonGroup from '../../../../../components/ButtonGroup/ButtonGroup';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import SectionTitle from '../../../../../components/Text/TextStyles';
import UserDetailsContext from '../../../../../contexts/UserDetailsContext/UserDetailsContext';
import useDate from '../../../../../hooks/useDate/useDate';
import { Sim, SimStatus } from '../../../../../models/UserDevicesModels';
import { RootState } from '../../../../../stores/Store';
import { updateRequest } from '../../../../../stores/TerminateConnectionStore';
import useTerminateConnectionSummary from './useTerminateConnectionSummary';

const RowsContainer = styled(Box)(() => ({
  paddingBottom: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

const Row = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
}));

const SectionValue = styled(Typography)(() => ({
  lineHeight: 'normal',
  marginLeft: 'auto',
  fontWeight: '700',
}));

const SectionLabel = styled(Typography)(() => ({
  lineHeight: 'normal',
}));

interface TerminateConnectionSummaryStepProps {
  onClose: () => void;
  onTerminateConnectionSuccess: () => void;
  sim: Sim;
}

function TerminateConnectionSummaryStep({
  onClose,
  onTerminateConnectionSuccess,
  sim,
}: TerminateConnectionSummaryStepProps) {
  const [t] = useTranslation();
  const user = useContext(UserDetailsContext);
  const { request, hasDistributionList } = useSelector((state: RootState) => state.terminateConnectionReducer);
  const { deviceToUpgrade } = useSelector((state: RootState) => state.upgradeDeviceReducer);
  const { localeDateFormat } = useDate();
  const dispatch = useDispatch();
  const { terminateConnection, isLoading } = useTerminateConnectionSummary(onClose, onTerminateConnectionSuccess);

  useEffect(() => {
    dispatch(updateRequest({ userId: user?.id, eid: deviceToUpgrade?.eid, phoneNumber: sim.phoneNumber }));
  }, [deviceToUpgrade, user, sim]);

  /**
   * Executes the terminate connection request
   */
  const handleConfirmButton = () => {
    terminateConnection(request);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingTop: '1rem' }}>
      {/* Summary */}
      <SectionTitle>{t('pages.userDetails.terminateConnectionDrawer.summary')}</SectionTitle>
      <RowsContainer>
        <Row>
          <SectionLabel>{t('pages.userDetails.terminateConnectionDrawer.userName')}</SectionLabel>
          <SectionValue>{`${user?.firstName} ${user?.lastName}`}</SectionValue>
        </Row>
        <Row>
          <SectionLabel sx={{ width: '25%' }}>{t('pages.userDetails.terminateConnectionDrawer.eSimId')}</SectionLabel>
          <SectionValue sx={{ width: '75%', textAlign: 'end' }}>{deviceToUpgrade?.eid}</SectionValue>
        </Row>
        <Row>
          <SectionLabel sx={{ width: '25%' }}>
            {t('pages.userDetails.terminateConnectionDrawer.phoneNumber')}
          </SectionLabel>
          <SectionValue sx={{ width: '75%', textAlign: 'end' }}>{sim.phoneNumber}</SectionValue>
        </Row>
        <Row>
          <SectionLabel>{t('pages.userDetails.terminateConnectionDrawer.device')}</SectionLabel>
          <SectionValue>{`${deviceToUpgrade?.manufacturer} ${deviceToUpgrade?.model}`}</SectionValue>
        </Row>
        <Row>
          <SectionLabel>{t('pages.userDetails.terminateConnectionDrawer.imei')}</SectionLabel>
          <SectionValue>{`${sim.imei || t('common.na')}`}</SectionValue>
        </Row>
        {sim.status !== SimStatus.SUSPENDED ? (
          <Row>
            <SectionLabel>{t('pages.userDetails.terminateConnectionDrawer.immediateSuspension')}</SectionLabel>
            <SectionValue>{request.suspendImmediately ? t('common.yes') : t('common.no')}</SectionValue>
          </Row>
        ) : null}
        <Row>
          <SectionLabel>{t('pages.userDetails.terminateConnectionDrawer.expectedDate')}</SectionLabel>
          <SectionValue>
            {request.terminationDate
              ? DateTime.fromISO(request.terminationDate).toFormat(localeDateFormat)
              : DateTime.now().plus({ days: 30 }).toFormat(localeDateFormat)}
          </SectionValue>
        </Row>
      </RowsContainer>
      <Divider
        sx={{
          borderColor: (theme) => theme.palette.monochrome.monoChrome3,
          marginBottom: '1.5rem',
        }}
      />
      <Box sx={{ marginBottom: '1rem' }}>
        <Checkbox
          checked={request.notifyAdmin}
          onChange={(e) => {
            dispatch(updateRequest({ notifyAdmin: e.currentTarget.checked }));
          }}
          label={t('pages.userDetails.terminateConnectionDrawer.sendMeNotifications')}
        />
      </Box>
      {hasDistributionList ? (
        <Box sx={{ marginBottom: '2.5rem' }}>
          <Checkbox
            checked={request.notifyDistributionList}
            onChange={(e) => {
              dispatch(updateRequest({ notifyDistributionList: e.currentTarget.checked }));
            }}
            label={t('pages.userDetails.terminateConnectionDrawer.sendDLNotifications')}
          />
        </Box>
      ) : null}

      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} />
          <Button
            label={t('common.confirm')}
            color="primaryButton"
            disabled={isLoading}
            onClick={handleConfirmButton}
          />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default TerminateConnectionSummaryStep;
