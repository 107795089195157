import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Drawer from '../../../../../components/Drawer/Drawer';
import { Sim } from '../../../../../models/UserDevicesModels';
import { reset } from '../../../../../stores/TerminateConnectionStore';
import TerminateConnectionDateSelectionStep from './TerminateConnectionDateSelectionStep';
import TerminateConnectionSummaryStep from './TerminateConnectionSummaryStep';
import useTerminateConnection from './useTerminateConnection';

interface TerminateConnectionDrawerProps {
  open: boolean;
  onClose: () => void;
  onTerminateConnectionSuccess: () => void;
  sim: Sim;
}
function TerminateConnectionDrawer({
  open,
  onClose,
  sim,
  onTerminateConnectionSuccess,
}: TerminateConnectionDrawerProps) {
  const dispatch = useDispatch();
  useTerminateConnection();

  /**
   * Handle the close action
   */
  const handleClose = () => {
    dispatch(reset());
    onClose();
  };
  const [t] = useTranslation();
  const [progress, setProgress] = useState(50);
  const [step, setStep] = useState(1);
  const NUMBER_OF_STEPS = 2;

  React.useEffect(() => {
    setProgress((step / NUMBER_OF_STEPS) * 100);
  }, [step]);

  /**
   * Handle the back button of the drawer to close or to go back a step
   */
  const handleBackButton = () => {
    if (step === 1) {
      handleClose();
    } else {
      setStep((prevState) => prevState - 1);
    }
  };

  /**
   * Render each step of the termination journey
   */
  const renderSteps = () => {
    switch (step) {
      case 1:
        return <TerminateConnectionDateSelectionStep onClose={handleClose} nextStep={() => setStep(2)} />;
      case 2:
        return (
          <TerminateConnectionSummaryStep
            onClose={handleClose}
            sim={sim}
            onTerminateConnectionSuccess={onTerminateConnectionSuccess}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Drawer
      disablePortal
      open={open}
      onClose={handleClose}
      onBackButton={handleBackButton}
      anchor="right"
      title={t('pages.userDetails.terminateConnectionDrawer.title')}
      progress={progress}
    >
      <Box sx={{ paddingTop: '1rem', height: '100%' }}>{renderSteps()}</Box>
    </Drawer>
  );
}

export default TerminateConnectionDrawer;
